import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Checkbox, DatePicker, message } from "antd";
import api from "../utils/useAxios";
import dayjs from "dayjs";
import "../App.css";
import locale from "antd/es/date-picker/locale/de_DE";
import { useAuthContext } from "../auth/AuthContext";
const CheckboxGroup = Checkbox.Group;

const ExportReports = ({ selectedDate, selectedReportType }) => {
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState(selectedDate);
  const [reportTypes, setReportType] = useState([selectedReportType] || []);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { currentAccount } = useAuthContext();

  useEffect(() => {
    console.log(selectedDate);
    setDate(selectedDate);
    form.setFieldsValue({ date: selectedDate });
  }, [selectedDate]);

  useEffect(() => {
    if (selectedReportType === "all") {
      setReportType(["itlaeuft", "legacy", "keeper"]);
      form.setFieldsValue({
        reportTypes: ["itlaeuft", "legacy", "keeper"],
      });
    } else {
      setReportType([selectedReportType] || []);
      form.setFieldsValue({ reportTypes: [selectedReportType] });
    }
  }, [selectedReportType]);

  const exportReports = () => {
    setLoading(true);
    messageApi.loading("Export wird durchgeführt...");
    try {
      api
        .post("export/customer-reports", {
          date: dayjs(date).format("YYYY-MM-DD"),
          reportTypes: reportTypes,
        })
        .then((response) => {
          console.log(response);
          setLoading(false);
          messageApi.destroy();
          messageApi.success(
            `Exportdatei erfolgreich an ${currentAccount.email} versandt. Bitte prüfe deinen Posteingang.`,
            5
          );
        });
    } catch (error) {
      setLoading(false);
      messageApi.destroy();
      messageApi.error(
        "Export fehlgeschlagen, bitte prüfe die Konsole auf Fehlermeldungen und wende dich an den Support"
      );
      console.log(error);
    }
  };

  const onFormFinish = (values) => {
    exportReports();
  };

  return (
    <div>
      {contextHolder}
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        className="btn-primary"
      >
        Export
      </Button>
      <Modal
        title="Berichte exportieren"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Abbrechen
          </Button>,
          <Button
            key="save"
            form="form"
            htmlType="submit"
            className="btn-primary"
            loading={loading}
          >
            Exportieren
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Form
            size="large"
            style={{ margin: 10 }}
            name="form"
            form={form}
            initialValues={{ reportTypes: reportTypes, date: date }}
            onFinish={onFormFinish}
            layout="vertical"
          >
            <Form.Item
              name="date"
              label={<strong>Berichtszeitraum</strong>}
              rules={[
                {
                  required: true,
                  message: "Bitte wähle ein Datum aus",
                },
              ]}
            >
              <DatePicker
                picker="month"
                onChange={(date) => setDate(date)}
                locale={locale}
                format={"MMMM YYYY"}
                value={selectedDate}
              />
            </Form.Item>

            <Form.Item
              name="reportTypes"
              label={<strong>Berichte auswählen</strong>}
              rules={[
                {
                  required: true,
                  message: "Bitte wähle mindestens einen Bericht aus",
                },
              ]}
            >
              <CheckboxGroup
                options={[
                  { label: "Vertragsprüfungen - IT.Läuft.", value: "itlaeuft" },
                  { label: "Vertragsprüfungen - Altverträge", value: "legacy" },
                  { label: "Vertragsprüfungen - Keeper", value: "keeper" },
                  { label: "Active Directory Daten", value: "adData" },
                  { label: "Gerätebericht", value: "devices" },
                ]}
                onChange={(value) => {
                  console.log(value);
                  setReportType(value);
                }}
                style={{ flexDirection: "column" }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ExportReports;
