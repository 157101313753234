import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  message,
  Select,
  Modal,
  Typography,
  Popconfirm,
  DatePicker,
  Divider,
  Tag,
  Tabs,
  Input,
  Tooltip,
} from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import useAuthorized from "../auth/useAuthorized";
import dayjs from "dayjs";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import SyncIcon from "@mui/icons-material/Sync";
import ExportReports from "./ExportReports";
const { Text, Title } = Typography;

const ActiveDirectoryData = () => {
  const [rows, setRows] = useState([]);
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showModal, setShowModal] = useState(false);
  const [showNoDataCustomersModal, setShowNoDataCustomersModal] =
    useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [noDataCustomers, setNoDataCustomers] = useState(null);
  const [modalStates, setModalStates] = useState({
    errors: [],
    warnings: [],
  });
  const [tagText, setTagText] = useState("");
  const [tagColor, setTagColor] = useState("");
  const [tagIcon, setTagIcon] = useState(null);
  const [form] = Form.useForm();
  const canWrite = useAuthorized(["activeDirectoryData.ReadWrite"]);
  const [managedCompanies, setManagedCompanies] = useState([]);
  const [activeModalTab, setActiveModalTab] = useState("included_group_users");

  const showModalData = (record) => {
    setModalErrorStates(record);
    form.setFieldsValue({
      company: record.company,
      domain: record.domain,
      source: record.source,
      date: record.date ? dayjs(record.date).format("DD.MM.YYYY HH:mm") : "N/A",
      updated: record.updated
        ? dayjs(record.updated).format("DD.MM.YYYY HH:mm")
        : "N/A",
    });
    setModalData(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData([]);
    setModalTitle("");
    setModalSubTitle("");
  };

  const setModalErrorStates = (record) => {
    let state = determineErrorStates(record);
    setModalStates(state);
  };

  const determineErrorStates = (value) => {
    let state = {
      errors: [],
      warnings: [],
    };
    if (!value.company) {
      state.errors.push({
        state: "error",
        description: "Keine Firma mit Datensatz verknüpft",
      });
    }
    if (value.assigned_to_both_groups.length > 0) {
      state.errors.push({
        state: "error",
        description:
          "Es existieren Benutzer, die in beiden Gruppen enthalten sind",
      });
    }
    if (value.assigned_to_no_group.length > 0) {
      state.errors.push({
        state: "error",
        description:
          "Es existieren Benutzer, die keiner Gruppe zugeordnet sind",
      });
    }
    if (value.disabled_and_included_users.length > 0) {
      state.warnings.push({
        state: "warning",
        description:
          "Es existieren deaktivierte Benutzer in der Gruppe 'IT.Laeuft - abrechenbar'",
      });
    }
    if (value.deprecated_and_included_users.length > 0) {
      state.warnings.push({
        state: "warning",
        description:
          "Es existieren Benutzer in der Gruppe 'IT.Laeuft - abrechenbar', die sich seit mehr als 90 Tagen nicht mehr angemeldet haben",
      });
    }
    return state;
  };

  const renderState = (state) => {
    let displayState =
      state.errors.length > 0
        ? "error"
        : state.warnings.length > 0
          ? "warning"
          : "success";
    let displayColor =
      displayState === "error"
        ? "red"
        : displayState === "warning"
          ? "orange"
          : "green";
    let displayIcon =
      displayState === "error" ? (
        <WarningOutlinedIcon
          style={{ color: displayColor, marginLeft: "8px" }}
        />
      ) : displayState === "warning" ? (
        <InfoOutlined style={{ color: displayColor, marginLeft: "8px" }} />
      ) : (
        <CheckCircle style={{ color: displayColor, marginLeft: "8px" }} />
      );
    let displayText =
      displayState === "error"
        ? "Fehlerhaft"
        : displayState === "warning"
          ? "Warnung"
          : "Fehlerfrei";
    return { displayColor, displayIcon, displayText };
  };

  const columns = [
    {
      title: "Kunde",
      dataIndex: ["company", "name"],
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.company?.name.localeCompare(b.company?.name),
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Domäne",
      dataIndex: "domain",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.domain.localeCompare(b.domain),
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Quelle",
      dataIndex: "source",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.source.localeCompare(b.source),
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "state",
      sorter: (a, b) => {
        const { displayText: displayTextA } = renderState(a.state);
        const { displayText: displayTextB } = renderState(b.state);
        return displayTextA.localeCompare(displayTextB);
      },
      render: (state, record) => {
        const { displayColor, displayIcon, displayText } = renderState(state);
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: displayColor }}>{displayText}</span>
            </strong>
            {displayIcon}
          </div>
        );
      },
    },
    {
      title: "Aktualisiert",
      dataIndex: "updated",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => dayjs(a.updated).unix() - dayjs(b.updated).unix(),
      defaultSortOrder: "ascend",
      render: (value, record) => {
        return value
          ? dayjs(value).format("DD.MM.YYYY HH:mm")
          : record.ad_data
            ? dayjs(record.ad_data.date).format("DD.MM.YYYY HH:mm")
            : "N/A";
      },
    },
    {
      title: "Details",
      key: "details",
      render: (_, record) => (
        <a onClick={() => showModalData(record)}>Details</a>
      ),
    },
  ];

  const handleMSPChange = (key, value) => {
    setCompany(value);
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };
  const getADData = async () => {
    try {
      const response = await api.get("customers/data/active-directory", {
        params: {
          msp: company.own_msp.id,
          date: selectedDate.format("YYYY-MM-DD"),
          dataOnly: true,
        },
      });
      const data = response.data.map((item) => {
        return {
          ...item,
          state: determineErrorStates(item),
        };
      });
      setRows(data);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der Daten");
    }
  };

  const getMSPCustomers = async () => {
    try {
      const response = await api.get("customers", {
        params: {
          msp: company.own_msp.id,
        },
      });
      setManagedCompanies(response.data);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der Daten");
    }
  };

  const getCustomersWithoutData = async () => {
    setNoDataCustomers(null);
    try {
      const response = await api.get("customers/data/active-directory", {
        params: {
          msp: company.own_msp.id,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });
      console.log("No Data Customers: ", response.data);
      setNoDataCustomers(response.data);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der Daten");
    }
  };

  const getAllData = async () => {
    setRetrievingData(true);
    try {
      await Promise.all([getADData(), getCustomersWithoutData()]);
      console.log("Selected Company ID: ", company?.id);
      console.log("Current Tenant ID: ", currentTenant.own_company.id);
      if (company?.id != currentTenant.own_company.id) {
        await getMSPCustomers();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRetrievingData(false);
    }
  };

  const patchADData = async (data) => {
    setRetrievingData(true);
    messageApi.loading("Daten werden gespeichert...");
    try {
      const response = await api.patch("customers/data/active-directory", {
        company: data.company,
        id: modalData?.id,
      });
      await getADData();
      messageApi.destroy();
      messageApi.success("Daten erfolgreich gespeichert");
      closeModal();
    } catch (error) {
      console.error(error);
      messageApi.destroy();
      messageApi.error(error.response.data.message);
    }
    setRetrievingData(false);
  };

  const updateData = async (adDataId = false) => {
    setRetrievingData(true);
    messageApi.loading("Daten werden aktualisiert...");
    try {
      let payload = {
        msp: company.own_msp.id,
        contractType: "ad_data",
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (adDataId) {
        payload["adDataId"] = adDataId;
      }
      const response = await api.post("contracts/check", payload);
      const record = response.data;
      setModalErrorStates(record);
      form.setFieldsValue({
        company: record.company?.id,
        domain: record.domain,
        source: record.source,
        date: record.date
          ? dayjs(record.date).format("DD.MM.YYYY HH:mm")
          : "N/A",
        updated: record.updated
          ? dayjs(record.updated).format("DD.MM.YYYY HH:mm")
          : "N/A",
      });
      setModalData(record);
      await getADData();
      await getCustomersWithoutData();
      messageApi.destroy();
      messageApi.success("Daten erfolgreich aktualisiert");
    } catch (error) {
      console.log(error);
      messageApi.destroy();
      messageApi.error(error.response.data.message);
    }
    setRetrievingData(false);
  };
  const updateAllData = async () => {
    setRetrievingData(true);
    messageApi.loading("Daten werden aktualisiert...");
    try {
      let payload = {
        msp: company.own_msp.id,
        contractType: "ad_data",
        date: selectedDate.format("YYYY-MM-DD"),
      };
      const response = await api.post("contracts/check", payload);
      await getADData();
      messageApi.destroy();
      messageApi.success("Daten erfolgreich aktualisiert");
    } catch (error) {
      console.log(error);
      messageApi.destroy();
      messageApi.error(error.response.data.message);
    }
    setRetrievingData(false);
  };

  const deleteADData = async () => {
    setRetrievingData(true);
    messageApi.loading("Daten werden gelöscht...");
    try {
      const response = await api.delete("customers/data/active-directory", {
        params: {
          id: modalData?.id,
        },
      });
      messageApi.destroy();
      messageApi.success("Daten erfolgreich gelöscht");
      closeModal();
      getADData();
    } catch (error) {
      console.error(error);
      messageApi.destroy();
      messageApi.error(error.response.data.message);
      setRetrievingData(false);
      closeModal();
    }
  };

  const ModalTag = ({ state }) => {
    let displayText = state.description;
    let target = null;
    const color =
      state.state === "error"
        ? "red"
        : state.state === "warning"
          ? "orange"
          : "success";
    if (
      displayText ===
      "Es existieren Benutzer, die keiner Gruppe zugeordnet sind"
    ) {
      target = "assigned_to_no_group";
    } else if (
      displayText ===
      "Es existieren Benutzer, die in beiden Gruppen enthalten sind"
    ) {
      target = "assigned_to_both_groups";
    } else if (
      displayText ===
      "Es existieren deaktivierte Benutzer in der Gruppe 'IT.Laeuft - abrechenbar'"
    ) {
      target = "disabled_and_included_users";
    } else if (
      displayText ===
      "Es existieren Benutzer in der Gruppe 'IT.Laeuft - abrechenbar', die sich seit mehr als 90 Tagen nicht mehr angemeldet haben"
    ) {
      target = "deprecated_and_included_users";
    }

    return (
      <Tag
        key={state.description}
        icon={
          state.state === "error" ? (
            <WarningIcon style={{ color: "red", margin: 5 }} />
          ) : state.state === "warning" ? (
            <InfoOutlined style={{ color: "orange", margin: 5 }} />
          ) : (
            <CheckCircle style={{ color: "green", margin: 5 }} />
          )
        }
        color={color}
        onClick={() => {
          target && setActiveModalTab(target);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "hidden",
          margin: 5,
          cursor: target ? "pointer" : "default",
        }}
      >
        {displayText}
      </Tag>
    );
  };
  const showMissingDataModal = () => {
    const data = noDataCustomers.map((item) => {
      return {
        value: item.name,
        key: item.id,
      };
    });
    setModalData(data);
    setShowNoDataCustomersModal(true);
  };

  const closeMissingDataModal = () => {
    setModalData([]);
    setShowNoDataCustomersModal(false);
  };

  useEffect(() => {
    if (currentTenant) {
      if (scope == "customers") {
        setCompany(currentTenant.own_company);
        setManagedCompanies(currentTenant.managed_companies);
      } else if (scope == "msps") {
        setRetrievingData(false);
        const options = currentTenant?.managed_msps;
        setCompanies(options);
      }
    } else {
      setRetrievingData(true);
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (company && selectedDate) {
      getAllData();
    }
  }, [company, selectedDate]);

  useEffect(() => {
    if (retrievingData) {
      setTagText("Daten werden geladen...");
      setTagColor("processing");
      setTagIcon(<SyncOutlined spin />);
    } else if (noDataCustomers?.length > 0) {
      setTagText(
        <a onClick={() => showMissingDataModal()}>
          <strong>
            Bei {noDataCustomers.length} IT.Läuft. Kunden sind keine Daten für
            den ausgewählten Zeitraum vorhanden
          </strong>
        </a>
      );
      setTagColor("error");
      setTagIcon(<WarningIcon style={{ color: "red", margin: 5 }} />);
    } else if (rows.length > 0 && noDataCustomers?.length === 0) {
      setTagText("Für alle IT.Läuft. Kunden sind Daten vorhanden");
      setTagColor("success");
      setTagIcon(<CheckCircle style={{ color: "green", margin: 5 }} />);
    } else if (rows.length === 0 && noDataCustomers?.length === 0) {
      setTagText("Keine Kunden mit IT.Läuft. Verträgen gefunden");
      setTagColor("warning");
      setTagIcon(<WarningIcon style={{ color: "orange", margin: 5 }} />);
    }
  }, [retrievingData, rows, noDataCustomers]);

  const TabTable = (dataType) => {
    const tableRows = modalData[dataType.dataType]?.map((item) => {
      return {
        key: item,
        value: item,
        label: item,
        title: item,
      };
    });
    const modalColumns = [
      {
        title: "Benutzer",
        dataIndex: "value",
      },
    ];
    return (
      <Table
        columns={modalColumns}
        rowKey="device_name"
        dataSource={tableRows}
        pagination={{
          total: 0,
          showTotal: (total) => `${total} Objekte`,
          defaultPageSize: 10,
          defaultCurrent: 1,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        scroll={{
          y: 250,
        }}
        loading={{
          spinning: retrievingData,
          indicator: <LoadingOutlined className="spinner-style" />,
        }}
        bordered
      />
    );
  };

  const tabItems = [
    {
      key: "included_group_users",
      label: "Abzurechnende Benutzer",
      children: <TabTable dataType="included_group_users" />,
    },
    {
      key: "assigned_to_both_groups",
      label: "Benutzer in beiden Gruppen",
      children: <TabTable dataType="assigned_to_both_groups" />,
    },
    {
      key: "assigned_to_no_group",
      label: "Benutzer ohne Gruppe",
      children: <TabTable dataType="assigned_to_no_group" />,
    },
    {
      key: "deprecated_and_included_users",
      label: "Inkludierte veraltete Benutzer",
      children: <TabTable dataType="deprecated_and_included_users" />,
    },
    {
      key: "disabled_and_included_users",
      label: "Inkludierte deaktivierte Benutzer",
      children: <TabTable dataType="disabled_and_included_users" />,
    },
  ];

  const onModalTabChange = (key) => {
    setActiveModalTab(key);
  };
  return (
    <div>
      <div className="table">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={rows}
          scroll={{
            y: 500,
          }}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          bordered
          pagination={false}
          title={() => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <div>
                  {" "}
                  <Title
                    level={3}
                    style={{
                      marginTop: -10,
                      marginBottom: 10,
                    }}
                  >
                    {labels.adData[scope].title}
                  </Title>
                  {company && (
                    <Tag
                      color={tagColor}
                      icon={tagIcon}
                      style={{
                        display: "flex", // Use flexbox for horizontal alignment
                        alignItems: "center", // Center items vertically
                        whiteSpace: "normal", // Allow text to wrap
                        wordWrap: "break-word", // Break long words
                        overflow: "hidden", // Hide overflow
                        margin: 5,
                      }}
                    >
                      {tagText}
                    </Tag>
                  )}
                </div>

                <Form
                  size="large"
                  style={{
                    margin: 10,
                  }}
                >
                  <Form.Item>
                    <Space direction="horizontal">
                      <ExportReports
                        selectedDate={selectedDate}
                        selectedReportType="adData"
                      />

                      <Popconfirm
                        title="Daten synchronisieren?"
                        description={`Es werden die aktuellsten Active Directory Daten aus allen verfügbaren Entra-IDs synchronisiert. Dieser Vorgang kann einige Minuten dauern.`}
                        okText="Ja, jetzt synchronisieren"
                        cancelText="Abbrechen"
                        onConfirm={async () => updateAllData()}
                      >
                        <Button
                          type="primary"
                          icon={<SyncIcon />}
                          className="btn-primary"
                        />
                      </Popconfirm>
                      {scope !== "customers" && (
                        <Select
                          autoFocus={true}
                          showSearch
                          placeholder={
                            labels.reports[scope].contractChecks.placeholder
                              .companies
                          }
                          optionFilterProp="children"
                          onChange={handleMSPChange}
                          style={{
                            width: 320,
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={companies}
                        />
                      )}
                      <DatePicker
                        onChange={onDateChange}
                        picker="month"
                        locale={locale}
                        format={"MMMM YYYY"}
                        style={{ width: 200 }}
                        value={selectedDate}
                      />
                      {contextHolder}
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
      <Modal
        title="Details Active Directory Daten"
        open={showModal}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={() => closeModal()}>
            Abbrechen
          </Button>,
          <Popconfirm
            key="delete"
            title="Daten löschen?"
            description={`Sind Sie sicher, dass Sie die Active Directory Daten für ${modalData?.company?.name} löschen möchten?`}
            okText="Ja, löschen"
            cancelText="Abbrechen"
            onConfirm={deleteADData}
          >
            <Button key="delete" type="primary" danger disabled={!canWrite}>
              Löschen
            </Button>
          </Popconfirm>,
          <Button
            key="save"
            form="form"
            htmlType="submit"
            disabled={!canWrite}
            className="btn-primary"
          >
            Speichern
          </Button>,
        ]}
        width="auto"
        style={{ maxWidth: "90%" }}
      >
        <div style={{ padding: 10 }}>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: "0 0 40%", paddingRight: "10px" }}>
              <Form
                style={{
                  margin: 10,
                }}
                form={form}
                onFinish={patchADData}
                name="form"
                id="form"
              >
                <Form.Item
                  name="company"
                  label="Verknüpfte Firma"
                  labelCol={{ style: { width: "150px" } }}
                  wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                  labelAlign="left"
                >
                  <Select
                    options={managedCompanies}
                    placeholder="Keine Firma verknüpft"
                    showSearch
                    disabled={!canWrite}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="domain"
                  label="Domäne"
                  labelCol={{ style: { width: "150px" } }}
                  wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                  labelAlign="left"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="source"
                  label="Quelle"
                  labelCol={{ style: { width: "150px" } }}
                  wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                  labelAlign="left"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="date"
                  label="Erstellt"
                  labelCol={{ style: { width: "150px" } }}
                  wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                  labelAlign="left"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="updated"
                  label="Aktualisiert"
                  labelCol={{ style: { width: "150px" } }}
                  wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                  labelAlign="left"
                  getValueProps={(value) => ({ value })}
                >
                  <Space.Compact
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input disabled value={form.getFieldValue("updated")} />
                    <Tooltip
                      title={
                        modalData?.source == "Entra-ID"
                          ? "Daten aus Entra-ID abrufen und Datensatz aktualisieren"
                          : "Aktualisierung nur bei Cloud-Datensätzen verfügbar"
                      }
                    >
                      <Button
                        className="btn-primary"
                        disabled={modalData?.source === "On-Premise"}
                        onClick={() => updateData(modalData?.id)}
                        loading={retrievingData}
                      >
                        Aktualisieren
                      </Button>
                    </Tooltip>
                  </Space.Compact>
                </Form.Item>
              </Form>
              <Divider />
              <div>
                {modalStates.errors.length === 0 &&
                  modalStates.warnings.length === 0 && (
                    <ModalTag
                      state={{
                        state: "success",
                        description: "Active Directory Daten fehlerfrei",
                      }}
                    />
                  )}
                {modalStates.errors.map((state) => (
                  <ModalTag state={state} />
                ))}
                {modalStates.warnings.map((state) => (
                  <ModalTag state={state} />
                ))}
              </div>
            </div>

            <Divider type="vertical" style={{ height: "auto", margin: 40 }} />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
              }}
            >
              <Tabs
                items={tabItems}
                style={{ flex: 1, minWidth: 0 }}
                activeKey={activeModalTab}
                onChange={onModalTabChange}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Kunden ohne Active Directory Daten"
        open={showNoDataCustomersModal}
        width={550}
        onCancel={() => setShowNoDataCustomersModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowNoDataCustomersModal(false)}>
            Schließen
          </Button>,
        ]}
      >
        <p>
          Bitte richte die IT.Läuft. Prüfung für diese Kunden{" "}
          <a
            href="https://einsnulleins.atlassian.net/wiki/spaces/EIN/pages/1351483435/Anleitung+Implementierung+der+automatischen+Userauswertung+f+r+IT.L+uft.+Kunden"
            target="_blank"
            rel="noopener noreferrer"
          >
            nach dieser Anleitung
          </a>{" "}
          ein
        </p>
        <div style={{ padding: 10 }}>
          <Table
            columns={[
              {
                title: "Kunde",
                dataIndex: "value",
              },
            ]}
            dataSource={modalData}
            pagination={{
              total: 0,
              showTotal: (total) => `${total} Kunden`,
              defaultPageSize: 10,
              defaultCurrent: 1,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            scroll={{
              y: 300,
            }}
            loading={{
              spinning: retrievingData,
              indicator: <LoadingOutlined className="spinner-style" />,
            }}
            bordered
          />
        </div>
      </Modal>
    </div>
  );
};

export default ActiveDirectoryData;
